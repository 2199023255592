import React from "react";
import AllNews from "../../pages/allNews/AllNews";
import NewsHero from "../../pages/newsHero/NewsHero";
import UsefulResources from "../../pages/usefulResources/UsefulResources";
import GoToPhoto from "../../pages/goToPhoto/GoToPhoto";
import NewsCarousel from "../../pages/newsCarousel/NewsCarousel";
import Hokim from "../../pages/hokim/Hokim";
import RegionInfo from "../../pages/regionInfo/RegionInfo";
import Test from "../../pages/test/Test";
import Statistika from "../../pages/statistika/Statistika";
import InteraktiveCart from "../../pages/interaktivCart/InteraktiveCart";
// import Socials from "../../pages/socials/Socials";

const Home = () => {
  return (
    <div>
      {/* <Socials /> */}
      <div className="lg:hidden md:hidden block text-[#495579]">
        <Test />
      </div>
      <div>
        <NewsCarousel />
      </div>
      <div className="lg:flex gap-3 mb-[40px] ">
        <div className="lg:w-[70%] bg-[#3B477F] p-[20px] mt-[40px] lg:mx-0 md:mx-4 mx-6">
          <NewsHero />
        </div>
        <div className="lg:w-[30%]">
          <div className="bg-[#3B477F] p-[20px] mt-[40px]  lg:mx-0 md:mx-4 mx-6">
            <Hokim />
          </div>
          <div className="bg-[#3B477F] p-[20px] mt-[10px]  lg:mx-0 md:mx-4 mx-6">
            <AllNews />
          </div>
        </div>
      </div>
      <div className="bg-[#3B477F] lg:block md:hidden hidden mt-[60px]">
        <Statistika />
      </div>
      {/* <div className="lg:block md:block hidden">
        <InteraktiveCart />
      </div> */}
      <div>
        <RegionInfo />
      </div>
      <GoToPhoto />
      <UsefulResources />
    </div>
  );
};

export default Home;
