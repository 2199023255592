import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BiCalendar } from "react-icons/bi";

const NewsHeroItem = ({ item }) => {
  const [readMore, setReadMore] = useState(false);
  let date = new Date(item.add_time);
  let dateMDY = `${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()} / ${date.getHours()} : ${date.getMinutes()}`;
  return (
    <div>
      <div className="h-[300px]">
        <div className="relative overflow-hidden bg-no-repeat bg-cover w-[100%] h-[230px]">
          <img
            src={item.post_img}
            alt="NewsHero"
            width={400}
            height={300}
            className="object-cover w-[500px] h-full hover:scale-125 transition duration-700 ease-in-out"
          />
        </div>
        <div className="text-white">
          <Link to={`pdetail/${item.slug}`}>
            {readMore ? item.title : `${item.title.substring(0, 180)} ...`}
            {item.title.substring(0, 180) ? (
              ""
            ) : (
              <button onClick={() => setReadMore(!readMore)}>
                {readMore ? "" : "batafsil"}
              </button>
            )}
          </Link>
        </div>
      </div>
      <p className="flex items-center gap-1 text-[13px] justify-end text-gray-200 mt-[50px]">
        <BiCalendar size={18} /> {dateMDY}
      </p>
      <div className="border-b-2 border-gray-400 pt-[10px] mb-[14px]"></div>
    </div>
  );
};

export default NewsHeroItem;
