import Slider from "react-slick";
import HeroItems from "./NewsCarouselItem";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useGetNewsCarousel } from "../../hooks/query";
import { Loader } from "@mantine/core";

const HeroNews = () => {
  const useGetNewsCarouselList = useGetNewsCarousel();

  if (useGetNewsCarouselList.isLoading) {
    return (
      <div className="flex justify-center h-[470px] items-center">
        <Loader color="#012a4a" size="xl" variant="oval" />
      </div>
    );
  }
  const settings = {
    // dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 9000,
    arrows: true,
    nextArrow: <NextBtn />,
    prevArrow: <PrevBtn />,
    className: "center",
    slidesToShow: 1,
  };

  function NextBtn(props) {
    const { onClick } = props;
    return (
      <button
        className="font-bold absolute top-[300px] right-4 bg-black/40 text-white -translate-y-1/2 lg:block md:block hidden"
        onClick={onClick}
      >
        <IoIosArrowForward size={40} />
      </button>
    );
  }

  function PrevBtn(props) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className="font-bold text-white bg-black/40 z-[1] absolute top-[300px] left-4 -translate-y-1/2 lg:block md:block hidden"
      >
        <IoIosArrowBack size={40} />
      </button>
    );
  }

  return (
    <div className="object-cover">
      <Slider {...settings}>
        {useGetNewsCarouselList.data?.map((item) => (
          <HeroItems item={item} key={item.id} />
        ))}
      </Slider>
    </div>
  );
};
export default HeroNews;
