import { useGetHokim } from "../../hooks/query";
import { Link } from "react-router-dom";
import { Loader } from "@mantine/core";
import { BsFillTelephoneFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Hokim = () => {
  const useGetHokimInfo = useGetHokim();
  const { t } = useTranslation();
  if (useGetHokimInfo.isLoading) {
    return (
      <div className="flex justify-center items-center h-[300px]">
        <Loader color="#FFFBEB" size="xl" variant="oval" />
      </div>
    );
  }
  return (
    <div>
      {useGetHokimInfo.data?.map((item) => (
        <div key={item.id} className="text-white">
          <img
            src={item.images}
            alt="hokim"
            className="flex justify-center mx-auto w-[350px] h-[300px] object-cover"
          />
          <div className="flex flex-col items-center">
            <h4 className="text-[24px] mt-[20px] ">{item.f_name}</h4>
            <p className="text-[20px] mt-[10px]">
              {t("O'rta Chirchiq tumani hokimi")}
            </p>
          </div>
          <div className="flex items-center gap-2 justify-center mt-[10px]">
            <BsFillTelephoneFill size={18} />
            <p className="text-[18px]">+998 70 762 95 00</p>
          </div>

          <div className="flex justify-center text-[16px] bg-gray-300 hover:bg-[#eef2ff] duration-300 w-[160px] mx-auto mt-[10px] py-2 rounded-lg">
            <Link to={`/murojaat/${item.id}`}>
              <button className="text-black">{t("Murojaat yuborish")}</button>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Hokim;
