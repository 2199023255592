import React from "react";
import chirchiq from "../../assets/images/chir.JPG";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";

const RegionInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="lg:flex justify-between">
      <div className="lg:flex flex-col gap-10">
        <h3 className="lg:w-[460px] lg:h-[128px] lg:text-[48px] lg:text-start md:text-start text-center text-[24px] font-[700] leading-[64px]">
          {t("O'rta Chirchiq tumani haqida")}
        </h3>
        <div className="lg:w-[400px] lg:h-[265px] shadow-xl relative overflow-hidden bg-no-repeat bg-cover">
          <img
            src={chirchiq}
            alt="region info"
            height={265}
            width={400}
            className="object-cover h-full hover:scale-125 transition duration-700 ease-in-out"
          />
        </div>
        <p className="lg:w-[400px] lg:h-[280px] font-[400] leading-[32px] bg-white p-4 shadow-lg">
          {t(
            "Tumanda 1 shahar (Nurafshon), 2 shaharcha (Tuyaboʻgʻiz, Yangihayot), 13 qishloq fuqarolari yigʻini (Angor, Doʻstlik, Istiqlol, Navoiy, Oxunboboyev, Oqota, Uyshun, Paxtakor, Paxtaobod, Yunuchqala, Yangiturmush, Oʻrtasaroy, Qorasuv) bor. Tuman markazi — Qorasuv shaharchasi."
          )}
        </p>
      </div>
      <div className="lg:flex flex-col">
        <p className="lg:w-[800px] lg:h-[140px] font-[400] leading-[32px] lg:mt-[170px] md:mt-[150px] mt-[10px] bg-white p-4 shadow-lg">
          {t(
            "Aholisi, asosan, qozoqlar, oʻzbeklar, shuningdek, tatar, rus, koreys va boshqalar millat vakillari ham yashaydi. Aholining oʻrtacha zichligi 1 km2ga 340,3 kishi (2023). Shahar aholisi 38,7 ming kishi, qishloq aholisi 124,7 ming kishi."
          )}
        </p>
        <p className="lg:w-[800px] lg:h-[192px] font-[400] leading-[32px] lg:mt-[40px] md:mt-[40px] mt-[10px] bg-white p-4 shadow-lg">
          {t(
            "Tuman hududi Chirchiq daryosining chap sohilidagi tekislikda joylashgan. Iqlimi kontinental. Qishi sovuq, yozi issiq. Yanvarning oʻrtacha temperaturasi 0—4°C, eng past temperatura 19 °C, —20°C. Iyulniki 28—30°C, eng yuqori temperatura 41—42°C. Oʻrtacha yillik yogʻin 450-460 mm. Vegetatsiya davri 300 kun. Tuman hududidan Polvonov nomidagi Toshkent, Qorasuv kanallari, shim.gʻarbidan Chirchiq daryosi oqib oʻtadi."
          )}
        </p>
        <p className="lg:w-[800px] lg:h-[170px] font-[400] leading-[32px] lg:mt-[40px] md:mt-[40px] mt-[10px] bg-white p-4 shadow-xl">
          {t(
            "Oʻrta Chirchiq tumanida Respublika sholikorlik ilmiy tadqiqot instituti, 74 umumiy taʼlim, bolalar sanʼat, sport maktablari, akademik litsey, gimnaziya, 2 kasb-hunar kolleji faoliyat koʻrsatadi. Tuman markaziy kutubxonasi, markaziy madaniyat uyi, 2 madaniyat va istirohat bogʻi, 8 klub muassasasi bor. 3 stadion, sport zallari, otish tirlari, futbol, voleybol, basketbol, qoʻl toʻpi maydonchalari mavjud."
          )}
        </p>
      </div>
    </div>
  );
};

export default RegionInfo;
